<template>
  <div class="message_warp">
    <div class="main_top">
      <div style="display: flex">
        <!-- <div class="block" style="margin-right: 20px">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div> -->
        <el-select
          v-model="proid"
          placeholder="请选择检察院名称"
          style="width: 300px"
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
            :disabled="disabled"
          ></el-option>
        </el-select>
        <el-input
          v-model="selectTxt"
          placeholder="请输入信息搜索"
          style="width: 300px; margin-left: 10px"
        ></el-input>
        <el-button
          slot="reference"
          @click="departSelect"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div style="display: flex">
        <el-button class="style_button" type="text" @click="handleAdd()"
          >添加</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      tooltip-effect="dark"
    >
      <el-table-column label="ID" width="50" type="index"></el-table-column>
      <el-table-column label="标题">
        <template slot-scope="scope">
          <p>{{ scope.row.title }}</p>
        </template>
      </el-table-column>
      <el-table-column label="发布人">
        <template slot-scope="scope">
          <p>{{ scope.row.username }}</p>
        </template>
      </el-table-column>
      <el-table-column label="发布院">
        <template slot-scope="scope">
          <p>{{ scope.row.procuratorate.refname }}</p>
        </template>
      </el-table-column>
      <el-table-column label="发布日期">
        <template slot-scope="scope">
          <p>{{ scope.row.date }}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="operate" width="400">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.row.id)"
            class="editColor"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleRemove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        :total="total"
        
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {
  selectNoticeInfoByType,
  deleteInfo
} from "@/api/message";
import { getProInfoList } from "@/api/system";

export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      perList: [],
      tableData: [], //干警列表
      total: 0, //总条数
      page: 1, //页数
      currentPage: 1, //当前页数
      selectTxt: "", //搜索当前项
      date: "", //时间
      type: 2, //常见问题
      pageSize: 10,
      proid: -1,
      proList:[],
      disabled: false,

    };
  },
  mounted() {
    this._selectNoticeInfoByType();
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    console.log(this.perList);
    this.typeid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).types.id;
   if( this.typeid == 6){
      this.proid = -1;
    }else{
      this.proid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
      this.disabled = true
    }
    this.getList()
  },
  methods: {
     //获取列表
    getList() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        this.proList = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: '全部'
        })
      });

    },
    //获取列表
    _selectNoticeInfoByType() {
      selectNoticeInfoByType(this.page,this.pageSize,this.type,this.selectTxt,this.proid).then(async (data) => {
        if (data.data == null) {
          this.page = 1;
          this.tableData = [];
          Message.error(data.msg);
          // await this._selectNoticeInfoByType();
          return;
        } else {
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
        }
      });
    },
    //添加
    handleAdd() {
      let res = this.perList.filter((val) => val == 64);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      this.$router.push(`./publish?type=problem`);
      }
    },
    //修改
    handleEdit(value) {
      let res = this.perList.filter((val) => val == 65);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      this.$router.push(`./publish?type=problem&id=${value}`);
      }
    },
    //删除
    handleRemove(id) {
      let res = this.perList.filter((val) => val == 66);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      this.$confirm("是否删除该篇文章？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteInfo(id).then(async (data) => {
            if (data.code !== 200) return Message.error("删除失败");
            console.log(data.code);
            await this._selectNoticeInfoByType();
          });
          Message.success("删除成功！");
        })
        .catch(() => {
          Message.info("已取消删除!");
        });
      }
    },
    //搜索
    departSelect() {
      this._selectNoticeInfoByType()
      // let name = this.selectTxt;
      // let endTime = "";
      // let startTime = "";
      // if (this.date !== null) {
      //   endTime = this.date[1] ;
      //   startTime = this.date[0];
      // }
      // this.page = 1;
      // let page = this.page;
      // let info = {
      //   endTime,
      //   name,
      //   page,
      //   startTime,
      // };
      // fuzzySearchCommonProblem(info).then((data) => {
      //   if (data.data == null) {
      //     Message.warning("查询内容不存在！");
      //   } else {
      //     this.tableData = data.data.list;
      //     this.total = data.data.total;
      //     this.currentPage = data.data.pageNum;
      //   }
      // });
    },

    //分页器
    handleCurrentChange(val) {
      this.page = val;
      this._selectNoticeInfoByType();
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
  },
  computed: {
    sexyOp() {
      return this.form.gender == "0" ? "男" : "女";
    },
  },
};
</script>
<style lang='less' >
.message_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-dialog {
    margin: 300px auto !important;
    width: 30% !important;
    min-width: 570px;
  }

  .el-dialog__header {
    border-bottom: 1px solid #e9e6e6;
  }
  .el-dialog__footer {
    border-top: 1px solid #e9e6e6;
  }
  .dialog_title {
    > img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    > span {
      font-size: 13px;
      font-weight: 400;
      color: #2d8cf0;
    }
  }
  .form_label {
    > span {
      color: red;
      margin-right: 5px;
    }
  }
  .el-form-item__label {
    text-align: left;
    .form_label {
      font-size: 13px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item .el-input__inner {
    width: 275px;
    height: 23px;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
  }
  .style_button_del {
    background: #ff0012;
    margin-left: 13px;
  }
  .el-table td,
  .el-table th {
    text-align: center;
    padding: 8px 0;
  }
  .el-table {
    margin-top: 15px;
    .el-button {
      width: 80px;
      height: 36px;
      font-size: 14px;
      color: #ffffff;
    }
    .editColor {
      background: #3a8fea;
    }
  }
  .el-button--danger {
    background: #ff5200 !important;
  }
  .el-pagination {
    // position: absolute;
    // bottom: 34px;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
.style_button_Sel {
  margin-left: 10px;
}
</style>